<template>
  <div style="background: #ffffff;min-height:900px;padding: 20px;">
    <!-- 菜单 -->
    <div class="menuone">
      <div class="menuone-title" @click="addOne()">
        <a-icon type="plus" />
        <span> 新建一级菜单</span>
      </div>
      <div class="menuone-con">
        <div
          class="menuone-listone"
          v-for="(item, index) in menus"
          :key="index"
        >
          <div class="menuone-listone-title">
            <div class="menuone-listone-title-l" @click="show(index)">
              <a-icon type="appstore" />
              <div>{{ item.menu_name }}</div>
            </div>
            <div v-if="item.show" class="menuone-listone-title-r">
              <div
                class="opera"
                style="color:#029C64"
                @click="editOne(item.dbId)"
              >
                <a-icon type="edit" />
                <div>修改</div>
              </div>
              <div
                class="opera"
                style="color:#FE3B3B"
                @click="deleteData(item.dbId)"
              >
                <a-icon type="delete" />
                <div>删除</div>
              </div>
              <div
                class="opera"
                style="color:#1890FF"
                v-if="index != 0"
                @click="sortAscOne(item.dbId, item.order_num || 0)"
              >
                <a-icon type="vertical-align-top" />
                <div>上移</div>
              </div>
              <div
                class="opera"
                style="color:#1890FF"
                v-if="index != menus.length - 1"
                @click="sortDescOne(item.dbId, item.order_num || 0)"
              >
                <a-icon type="vertical-align-bottom" />
                <div>下移</div>
              </div>
              <div
                class="opera"
                style="color:#1890FF"
                @click="addTwo(item.dbId, item.label)"
              >
                <a-icon type="plus" />
                <div>增加子菜单</div>
              </div>
            </div>
          </div>
          <div
            class="menuone-listtwo"
            v-for="(item2, index2) in item.children"
            :key="index2"
          >
            <div class="menuone-listone-title">
              <div class="menuone-listone-title-l" @click="show(index, index2)">
                <a-icon v-if="item2.icon" :type="item2.icon" />
                <div>{{ item2.menu_name }}</div>
              </div>
              <div class="menuone-listone-title-r" v-if="item2.show">
                <div
                  class="opera"
                  style="color:#029C64"
                  @click="editTwo(item.dbId, item.menu_name, item2.dbId)"
                >
                  <a-icon type="edit" />
                  <div>修改</div>
                </div>
                <div
                  class="opera"
                  style="color:#FE3B3B"
                  @click="deleteData(item2.dbId)"
                >
                  <a-icon type="delete" />
                  <div>删除</div>
                </div>
                <div
                  class="opera"
                  style="color:#1890FF"
                  v-if="index2 != 0"
                  @click="
                    sortAscTwo(item.dbId, item2.dbId, item2.order_num || 0)
                  "
                >
                  <a-icon type="vertical-align-top" />
                  <div>上移</div>
                </div>
                <div
                  class="opera"
                  style="color:#1890FF"
                  v-if="index2 != item.children.length - 1"
                  @click="
                    sortDescTwo(item.dbId, item2.dbId, item2.order_num || 0)
                  "
                >
                  <a-icon type="vertical-align-bottom" />
                  <div>下移</div>
                </div>
                <div
                  class="opera"
                  style="color:#1890FF"
                  @click="addTwo(item2.dbId, item2.menu_name)"
                >
                  <a-icon type="plus" />
                  <div>增加子菜单</div>
                </div>
              </div>
            </div>
            <div
              class="menuone-listthree"
              v-for="(item3, index3) in item2.children"
              :key="index3"
            >
              <div class="menuone-listone-title">
                <div
                  class="menuone-listone-title-l"
                  @click="show(index, index2, index3)"
                >
                  <div>{{ item3.menu_name }}</div>
                </div>
                <div v-if="item3.show" class="menuone-listone-title-r">
                  <div
                    class="opera"
                    style="color:#029C64"
                    @click="editTwo(item2.dbId, item2.menu_name, item3.dbId)"
                  >
                    <a-icon type="edit" />
                    <div>修改</div>
                  </div>
                  <div
                    class="opera"
                    style="color:#FE3B3B"
                    @click="deleteData(item3.dbId)"
                  >
                    <a-icon type="delete" />
                    <div>删除</div>
                  </div>
                  <div
                    class="opera"
                    style="color:#1890FF"
                    v-if="index3 != 0"
                    @click="
                      sortAscTwo(item2.dbId, item3.dbId, item3.order_num || 0)
                    "
                  >
                    <a-icon type="vertical-align-top" />
                    <div>上移</div>
                  </div>
                  <div
                    class="opera"
                    style="color:#1890FF"
                    v-if="index3 != item2.children.length - 1"
                    @click="
                      sortDescTwo(item2.dbId, item3.dbId, item3.order_num || 0)
                    "
                  >
                    <a-icon type="vertical-align-bottom" />
                    <div>下移</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 修改系统名称 -->
    <div class="menuone" style="margin-top: 40px;">
      <div class="menuone-title" @click="toRevise()">
        <span>修改系统名称</span>
      </div>
      <div class="menuone-revise">
        <img src="" alt="" />
        <div>{{ sysname }}</div>
      </div>
    </div>

    <!-- 删除弹框 -->
    <div>
      <a-modal
        title="提示"
        :visible="visible"
        :confirm-loading="confirmLoading"
        @ok="handleDeleteOk"
        @cancel="handleCancel"
        ok-text="确认"
        cancel-text="取消"
      >
        <p>{{ ModalText }}</p>
      </a-modal>
    </div>

    <!-- 新建一级菜单 -->
    <div>
      <a-modal
        :title="form.title"
        :visible="visible1"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
        ok-text="立即修改"
        cancel-text="取消"
      >
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item ref="name" label="一级菜单名称：" prop="name">
            <a-input
              placeholder="请输入一级菜单名称"
              v-model="form.name"
              @blur="
                () => {
                  $refs.name.onFieldBlur();
                }
              "
            />
          </a-form-model-item>
          <a-form-model-item ref="url" label="菜单链接：" prop="url">
            <a-input
              placeholder="请输入URL地址"
              v-model="form.url"
              @blur="
                () => {
                  $refs.url.onFieldBlur();
                }
              "
            />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>

    <!-- 新建子菜单 -->
    <div>
      <a-modal
        title="新建子菜单"
        :visible="visible2"
        :confirm-loading="confirmLoading"
        @ok="handleOk"
        @cancel="handleCancel"
        ok-text="立即修改"
        cancel-text="取消"
      >
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item ref="" label="一级菜单" prop="">
            {{ form.parent_name }}
          </a-form-model-item>
          <a-form-model-item ref="name" label="子菜单名称：" prop="name">
            <a-input
              placeholder="请输入二级菜单名称"
              v-model="form.name"
              @blur="
                () => {
                  $refs.name.onFieldBlur();
                }
              "
            />
          </a-form-model-item>
          <a-form-model-item ref="url" label="菜单链接：" prop="url">
            <a-input
              placeholder="请输入URL地址"
              v-model="form.url"
              @blur="
                () => {
                  $refs.url.onFieldBlur();
                }
              "
            />
          </a-form-model-item>
          <a-form-model-item label="菜单图标：" prop="icon">
            <a-select
              v-model="form.icon"
              placeholder="请选择图标"
              :default-value="form.icon"
            >
              <a-select-option value="bg-colors">
                <a-icon type="bg-colors" />
              </a-select-option>
              <a-select-option value="font-colors">
                <a-icon type="font-colors" />
              </a-select-option>
              <a-select-option value="fund">
                <a-icon type="fund" />
              </a-select-option>
              <a-select-option value="ant-design">
                <a-icon type="ant-design" />
              </a-select-option>
              <a-select-option value="bank">
                <a-icon type="bank" />
              </a-select-option>
              <a-select-option value="block">
                <a-icon type="block" />
              </a-select-option>
              <a-select-option value="gold">
                <a-icon type="gold" />
              </a-select-option>
              <a-select-option value="user">
                <a-icon type="user" />
              </a-select-option>
              <a-select-option value="unordered-list">
                <a-icon type="unordered-list" />
              </a-select-option>
              <a-select-option value="line-chart">
                <a-icon type="line-chart" />
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item ref="perms" label="权限标识：" prop="perms">
            <a-input
              @blur="
                () => {
                  $refs.perms.onFieldBlur();
                }
              "
              placeholder="请输入权限标识"
              v-model="form.perms"
            />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>
<script>
import {
  getMenuList,
  createMenu,
  showMenu,
  editMenu,
  deleteMenu,
  moveMenu,
  showConfig
} from "@/api/permission/menu/index";
export default {
  data() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 12 },
      form: {
        id: "",
        parent_id: "",
        parent_name: "",
        title: "新建一级菜单",
        name: "",
        url: "",
        icon: "bg-colors",
        perms: ""
      },
      menuId: 0,
      configId: 0,
      rules: {
        name: [{ required: true, message: "请输入系统名称", trigger: "blur" }],
        url: [{ required: false, message: "请上传Logo", trigger: "blur" }],
        icon: [{ required: false, message: "请选择图标", trigger: "change" }],
        perms: [{ required: true, message: "请输入权限标识", trigger: "blur" }]
      },
      ModalText: "确定要删除该菜单吗？",
      visible: false, //删除弹框是否展示
      confirmLoading: false,
      visible1: false, //新建一级菜单弹框是否展示
      visible2: false,
      menus: [],
      sysname: "山西省农村供水信息化技术平台"
    };
  },
  created() {
    this.getMenus();
    this.getSysConfig();
    this.getSysConfig();
  },
  methods: {
    //修改系统名称
    toRevise() {
      this.$router.push({
        path: "/index/auth/menu/revise"
      });
    },
    getSysConfig() {
      showConfig({
        configKey: "system_name"
      }).then(res => {
        if (res.code == 200) {
          this.sysname = res.data.config_value;
        }
      });
    },
    //打开删除弹框
    deleteData(id) {
      this.menuId = id;
      this.visible = true;
    },
    handleDeleteOk() {
      let _this = this;
      deleteMenu({
        menuId: _this.menuId
      }).then(res => {
        this.visible = false;
        if (res.code == 200) {
          _this.$message.success("删除成功", function() {
            _this.getMenus();
          });
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    //确定
    handleOk() {
      this.visible = false;
      this.visible1 = false;
      this.visible2 = false;
      let id = this.form.id;
      let _this = this;
      if (id) {
        this.updateMenu({
          dbId: id,
          menu_name: _this.form.name,
          component: _this.form.url,
          icon: _this.form.icon,
          parent_id: _this.form.parent_id,
          perms: _this.form.perms
        });
      } else {
        this.addMenu({
          menu_name: _this.form.name,
          component: _this.form.url,
          icon: _this.form.icon,
          parent_id: _this.form.parent_id,
          perms: _this.form.perms
        });
      }
    },
    //取消删除
    handleCancel() {
      this.visible = false;
      this.visible1 = false;
      this.visible2 = false;
    },
    //新建一级菜单
    addOne() {
      this.form.name = "";
      this.form.url = "";
      this.form.id = 0;
      this.form.parent_id = 0;
      this.form.parent_name = 0;
      this.visible1 = true;
    },
    editOne(id) {
      console.log(id);
      showMenu({
        menuId: id
      }).then(res => {
        if (res.code == 200) {
          this.form.title = "编辑一级菜单";
          this.form.name = res.data.menu_name;
          this.form.url = res.data.component;
          this.form.id = res.data.dbId;
        }
      });
      this.visible1 = true;
    },
    // 一级菜单排序 - 上移
    sortAscOne(id, order_num) {
      this.operateMenu("menuId=" + id + "&type=1");
    },
    // 一级菜单排序 - 下移
    sortDescOne(id, order_num) {
      this.operateMenu("menuId=" + id + "&type=2");
    },
    // 二级菜单排序 - 上移
    sortAscTwo(parent_id, id, order_num) {
      this.operateMenu("menuId=" + id + "&type=1");
    },
    // 二级菜单排序 - 下移
    sortDescTwo(parent_id, id, order_num) {
      this.operateMenu("menuId=" + id + "&type=2");
    },
    //新建二级菜单
    addTwo(id, name) {
      this.form.parent_name = name;
      this.form.parent_id = id;
      this.form.name = "";
      this.form.url = "";
      this.form.icon = "";
      this.form.id = 0;
      this.visible2 = true;
    },
    editTwo(parent_id, parent_name, id) {
      console.log(id);
      showMenu({
        menuId: id
      }).then(res => {
        if (res.code == 200) {
          this.form.title = "编辑子菜单";
          this.form.name = res.data.menu_name;
          this.form.url = res.data.component;
          this.form.id = res.data.dbId;
          this.form.parent_name = parent_name;
          this.form.parent_id = parent_id;
        }
      });
      this.visible2 = true;
    },
    addMenu(data) {
      let _this = this;
      createMenu(data).then(res => {
        if (res.code == 200) {
          _this.$message.success("新增成功", function() {
            _this.getMenus();
          });
        } else {
          _this.$message.error("新增失败");
        }
      });
    },
    updateMenu(data) {
      let _this = this;
      editMenu(data).then(res => {
        if (res.code == 200) {
          _this.$message.success("编辑成功", function() {
            _this.getMenus();
          });
        } else {
          _this.$message.error("编辑失败");
        }
      });
    },
    operateMenu(data) {
      let _this = this;
      moveMenu(data).then(res => {
        if (res.code == 200) {
          _this.$message.success("移动成功", function() {
            _this.getMenus();
          });
        } else {
          _this.$message.error("移动失败");
        }
      });
    },
    // 操作按钮展示隐藏
    show(index, index2, index3) {
      console.log(typeof index, index2, index3);
      if (index != undefined && index2 == undefined && index3 == undefined) {
        this.menus[index].show = !this.menus[index].show;
        this.$set(this.menus, index, this.menus[index]);
      }
      if (index != undefined && index2 != undefined && index3 == undefined) {
        this.menus[index].children[index2].show = !this.menus[index].children[
          index2
        ].show;
        this.$set(this.menus, index, this.menus[index]);
      }
      if (index != undefined && index2 != undefined && index3 != undefined) {
        this.menus[index].children[index2].children[index3].show = !this.menus[
          index
        ].children[index2].children[index3].show;
        this.$set(this.menus, index, this.menus[index]);
      }
      console.log(this.menus);
    },
    getMenus() {
      this.menus = [];
      getMenuList().then(res => {
        // console.log(res);
        if (res.code == 200) {
          this.menus = res.data;
          for (let i = 0; i < res.data.length; i++) {
            this.menus[i].show = false;
            if (this.menus[i].children.length > 0) {
              for (let y = 0; y < this.menus[i].children.length; y++) {
                this.menus[i].children[y].show = false;
                if (this.menus[i].children[y].children.length > 0) {
                  for (
                    let z = 0;
                    z < this.menus[i].children[y].children.length;
                    z++
                  ) {
                    this.menus[i].children[y].children[z].show = false;
                  }
                }
              }
            }
          }

          console.log(this.menus);
          localStorage.setItem("menus", JSON.stringify(this.menus));
          this.$store.commit("setMenus", this.menus);
        }
      });
    }
  }
};
</script>

<style scoped lang="less">
.menuone {
  .menuone-title {
    width: 150px;
    height: 30px;
    line-height: 30px;
    background: #1890ff;
    border-radius: 4px;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
  }
  .menuone-con {
    padding-top: 20px;
    .menuone-listone {
      .menuone-listone-title {
        display: flex;
        align-items: center;
        height: 50px;
        padding-left: 20px;
        .menuone-listone-title-l {
          display: flex;
          align-items: center;
          font-size: 16px;
          cursor: pointer;
          div {
            margin-left: 5px;
          }
        }
        .menuone-listone-title-r {
          display: flex;
          align-items: center;
          font-size: 14px;
          margin-left: 200px;
          .opera {
            display: flex;
            align-items: center;
            margin-left: 40px;
            cursor: pointer;
            div {
              margin-left: 5px;
            }
          }
        }
      }
      .menuone-listtwo {
        padding-left: 20px;
        .menuone-listone-title {
          display: flex;
          align-items: center;
          height: 50px;
          padding-left: 20px;
          .menuone-listone-title-l {
            display: flex;
            align-items: center;
            font-size: 14px;
            cursor: pointer;
            div {
              margin-left: 5px;
            }
          }
          .menuone-listone-title-r {
            display: flex;
            align-items: center;
            font-size: 14px;
            margin-left: 200px;
            .opera {
              display: flex;
              align-items: center;
              margin-left: 40px;
              cursor: pointer;
              div {
                margin-left: 5px;
              }
            }
          }
        }
        .menuone-listthree {
          padding-left: 15px;
          .menuone-listone-title {
            display: flex;
            align-items: center;
            height: 50px;
            padding-left: 20px;
            .menuone-listone-title-l {
              display: flex;
              align-items: center;
              font-size: 14px;
              cursor: pointer;
              div {
                margin-left: 5px;
              }
            }
            .menuone-listone-title-r {
              display: flex;
              align-items: center;
              font-size: 14px;
              margin-left: 200px;
              .opera {
                display: flex;
                align-items: center;
                margin-left: 40px;
                cursor: pointer;
                div {
                  margin-left: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
  .menuone-revise {
    display: flex;
    align-items: center;
    margin-top: 20px;
    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 1px solid rgba(187, 187, 187, 1);
    }
    div {
      margin-left: 5px;
    }
  }
}
</style>
